
import {
    defineComponent, Ref, ref
} from 'vue';
import noticeBase from '@/util/notice.base';
import enlargeImage from '../enlarge-image/index.vue';

export default defineComponent({
    emits: ['change', 'remove'],
    components: {
        enlargeImage
    },
    expose: ['remove'],
    props: {
        accept: {
            type: String,
            default: '.jpg,.png,.bmp'
        }
    },
    setup(props, { emit }) {
        const fileInput: Ref<any> = ref();
        const chooseImg: Ref<any> = ref();
        const deleteBtn: Ref<any> = ref();
        const isFile = ref(false);

        const isShowLarge = ref(false);
        const fileUrl = ref('');

        const choose = () => {
            fileInput.value.click();
        };

        const change = () => {
            const file = fileInput.value.files[0];
            if (!/\.(?:jpg|png|bmp)$/.test(file.name)) {
                noticeBase.messageBox('alert', WordList.ImportFaceExtError, WordList.Notice, 'info')(() => false);
                return;
            }
            isFile.value = true;
            emit('change', fileInput.value.files[0]);
            chooseImg.value.src = window.URL.createObjectURL(fileInput.value.files[0]);
            fileUrl.value = window.URL.createObjectURL(fileInput.value.files[0]);
            chooseImg.value.onload = () => {
                if (chooseImg.value.clientWidth > chooseImg.value.clientHeight) {
                    chooseImg.value.style.width = '100%';
                    chooseImg.value.style.height = 'auto';
                } else {
                    chooseImg.value.style.width = 'auto';
                    chooseImg.value.style.height = '100%';
                }
                deleteBtn.value.style.right = `${246 - chooseImg.value.clientWidth}px`;
                deleteBtn.value.style.top = `${chooseImg.value.clientHeight - deleteBtn.value.clientHeight}px`;
            };
        };

        const remove = () => {
            emit('remove');
            isFile.value = false;
            fileInput.value.value = '';
            fileUrl.value = '';
        };

        return {
            fileInput,
            chooseImg,
            isFile,
            deleteBtn,
            choose,
            change,
            remove,
            isShowLarge,
            fileUrl
        };
    }
});
