
import {
    defineComponent, ref, watch
} from 'vue';
import propertyBreadCrumb from '@/components/view/pm/bread-crumb';
import router, { propertySubRouter, propertyBaseRouter } from '@/router';
import { device, community, account } from '@/data';
import HttpRequest from '@/util/axios.config';
import chooseImg from '@/components/common/choose-image';
import setAccess from '@/components/view/pm/set-access';
import accessGroupModel from '@/components/view/pm/access-group-model';
import noticeBase from '@/util/notice.base';
import { form as userForm, FormData as BasicData, officeForm } from '@/components/view/pm/set-resident';
import pinEncryptInput from '@/components/common/pin-encrypt-input';
import { residentApi } from '@/api';
import { tipsDialog as paymentTipsDialog } from '@/components/view/common/payment/index';
import payApp from '@/components/view/common/subscription/module/pay/pay-app.vue';
import createSubCallback from '@/methods/createSubCallback';
import { initForm, initRule, initSelfAccess } from './util';

const officeRole: {
    [key in number]: string;
} = {
    [account.personnelRole]: WordList.BasicUser,
    [account.staffRole]: WordList.AdvancedUser
};
export default defineComponent({
    components: {
        propertyBreadCrumb,
        chooseImg,
        setAccess,
        accessGroupModel,
        userForm,
        pinEncryptInput,
        officeForm,
        paymentTipsDialog,
        payApp
    },
    setup() {
        const projectType = community.ProjectType;
        const breadHeaders = ref([{
            label: WordList.RDeviceResidents,
            path: `/${propertyBaseRouter}/${propertySubRouter.resident}`
        }, {
            label: WordList.ProperAllTextNew
        }]);
        watch(projectType, () => {
            if (projectType.value === 'office') {
                breadHeaders.value[0].label = WordList.ProperAllTextPeople;
            }
        }, {
            immediate: true
        });
        const step = ref(0);
        const { ruleAccess } = initRule();
        const {
            accessForm, fileData, chooseFile, removeFile, basicData
        } = initForm();
        const selfAccessForm = initSelfAccess();
        const buildName = ref('');
        const aptName = ref('');
        const haveSelfDevices = ref(false);
        const isShowSelfAccess = ref(false);
        const selfAccessCommit = ref(0);
        const accountType = ref('main');
        const employeeID = ref('');
        const formCommit = ref(0);
        let firstStepType: 'next'|'save' = 'next';
        console.log(1);

        const checkForm = (type: 'next'|'save' = 'next') => {
            firstStepType = type;
            formCommit.value += 1;
        };

        // 路由跳转到resident
        const routeGoToResident = () => {
            router.push(`/${propertyBaseRouter}/${propertySubRouter.resident}`);
        };

        // v6.4 ,控制展示支付弹窗和pay by app ,zh
        const isShowTips = ref(false);
        const isShowPay = ref(false);
        const isShowPayNow = () => {
            isShowTips.value = false;
            isShowPay.value = true;
        };
        const closePayTips = () => {
            isShowTips.value = false;
            routeGoToResident();
        };

        // 支付订单信息
        const mainID = ref('');
        const payUserID = ref('');

        const noticeType = ref('');
        const noticeMainCode = ref();
        const createUserCallBack = (res: {
            Active: 0|1;
            TipCode: number;
            msg: string;
            MainID: string;
            ID: string;
        }) => {
            createSubCallback(
                res,
                (type: 'main'|'sub'|'') => {
                    isShowTips.value = false;
                    noticeMainCode.value = res.TipCode;
                    noticeType.value = type;
                    mainID.value = res.MainID;
                    payUserID.value = res.ID;
                    isShowTips.value = true;
                },
                routeGoToResident
            );
        };
        const curFloor = ref('');
        const formSubmit = (data: {
            basicData: BasicData;
            buildName: string;
            aptName: string;
            accountType: 'main'|'sub';
            floor: string;
        }) => {
            basicData.value = data.basicData;
            buildName.value = data.buildName;
            aptName.value = data.aptName;
            accountType.value = data.accountType;
            curFloor.value = data.floor;
            if (firstStepType === 'save') {
                const params = { ...data.basicData, Step: 0 };
                if (accountType.value === 'main') {
                    residentApi.addNewMainUserForPM(params, () => {
                        routeGoToResident();
                    });
                } else {
                    residentApi.addNewSubUserForPM(params, createUserCallBack);
                }
            } else if (firstStepType === 'next') {
                const url = accountType.value === 'main' ? 'addMainUserCheck' : 'addSubUserCheck';
                residentApi[url]({ ...data.basicData, Step: 0 }, [() => {
                    step.value = 1;
                }, false]);
            }
        };

        // 6.3 办公下的信息提交
        const officeFormSubmit = (data: {
            basicData: BasicData;
            departmentName: string;
            employeeID: string;
            floor: string;
        }) => {
            basicData.value = data.basicData;
            buildName.value = data.departmentName;
            employeeID.value = data.employeeID;
            // 6.5.3 办公梯控需求
            if (data.floor) {
                accessForm.Floor = data.floor === 'all' ? ['all'] : data.floor.split(';');
            } else {
                accessForm.Floor = [];
            }
            if (firstStepType === 'save') {
                HttpRequest.post('v3/web/office/user/addOfficeUser', { ...data.basicData, Step: 0 }, () => {
                    router.push(`/${propertyBaseRouter}/${propertySubRouter.resident}`);
                });
            } else if (firstStepType === 'next') {
                HttpRequest.post('v3/web/office/user/addOfficeUserCheck', { ...data.basicData, Step: 0 }, [() => {
                    step.value = 1;
                }, false]);
            }
        };

        const roomChange = (value: string) => {
            device.getUserDoor(value, (data:
            Array<{MAC: string; Relay: string;SecurityRelay: string; ValidRelayKey: string;ValidSecurityRelayKey: string}>) => {
                haveSelfDevices.value = data.length !== 0;
                const temp = data;
                temp.forEach((item, index) => {
                    temp[index].Relay = item.ValidRelayKey;
                    temp[index].SecurityRelay = item.ValidSecurityRelayKey;
                });
                selfAccessForm.Device = temp;
            });
        };

        const multipleSelectValue = (data: Array<string>) => {
            accessForm.AccessGroup = data;
        };

        const sysAccess = ref<string[]>([]);
        const getSysAccess = (data: string[]) => {
            sysAccess.value = data;
        };

        let formData = new FormData();
        const getSelfAccess = (value: typeof selfAccessForm) => {
            selfAccessForm.SchedulerType = value.SchedulerType;
            selfAccessForm.StartDay = value.StartDay;
            selfAccessForm.StopDay = value.StopDay;
            selfAccessForm.StartTime = value.StartTime;
            selfAccessForm.StopTime = value.StopTime;
            selfAccessForm.DateFlag = value.DateFlag;
            selfAccessForm.Device = value.Device;

            formData.append('SchedulerType', selfAccessForm.SchedulerType);
            formData.append('StartDay', selfAccessForm.StartDay);
            formData.append('StopDay', selfAccessForm.StopDay);
            formData.append('StartTime', selfAccessForm.StartTime);
            formData.append('StopTime', selfAccessForm.StopTime);
            formData.append('DateFlag', selfAccessForm.DateFlag);
            formData.append('SchedulerType', selfAccessForm.SchedulerType);

            selfAccessForm.Device.forEach((item, index) => {
                formData.append(`Device[${index}][MAC]`, item.MAC);
                formData.append(`Device[${index}][Relay]`, item.Relay || '');
                formData.append(`Device[${index}][SecurityRelay]`, item.SecurityRelay || '');
            });
            if (projectType.value === 'office') {
                residentApi.addOfficeUserForPM(formData, () => {
                    routeGoToResident();
                });
            } else if (accountType.value === 'main') {
                residentApi.addNewMainUserForPM(formData, createUserCallBack);
            } else {
                residentApi.addNewSubUserForPM(formData, [createUserCallBack, false]);
            }
        };

        const accessFormRef = ref();
        const submitAccess = () => {
            accessFormRef.value.validate((valid: boolean) => {
                if (valid) {
                    formData = new FormData();
                    // 检查权限组是否勾选
                    if (accessForm.AccessGroup.length === 0) {
                        noticeBase.messageBox('alert', WordList.ProperAllTextDelOrStaffAccessGroupTip, WordList.PersonuserInfo, 'info')(() => false);
                        return false;
                    }

                    // 检查pin和card, face至少勾选一个
                    if (accessForm.PIN === '' && accessForm.Card === '' && fileData.value === '') {
                        noticeBase.messageBox('alert', WordList.ProperAllTextSetAccessType, WordList.PersonuserInfo, 'info')(() => false);
                        return false;
                    }
                    formData.append('Step', '1');
                    Object.keys(basicData.value).forEach((key) => {
                        const index = key as keyof typeof basicData.value;
                        const value = basicData.value[index];
                        formData.append(index, value.toString());
                    });
                    formData.append('PIN', accessForm.PIN);
                    formData.append('Card', accessForm.Card);
                    accessForm.AccessGroup.forEach((item, index) => {
                        formData.append(`AccessGroup[${index}]`, item);
                    });
                    formData.append('Face', fileData.value);
                    formData.append('Floor', accessForm.Floor.includes('all') ? 'all' : accessForm.Floor.join(';'));
                    formData.append('AccessFloor', accessForm.AccessFloor.includes('all') ? 'all' : accessForm.AccessFloor.join(';'));
                    // 自己权限组验证
                    if (
                        haveSelfDevices.value
                        && community.hasHaveAccessArea.value
                        && projectType.value === 'community'
                    ) {
                        selfAccessCommit.value += 1;
                    } else {
                        getSelfAccess({
                            Name: '',
                            SchedulerType: '1',
                            StartDay: '',
                            StopDay: '',
                            StartTime: '00:00:00',
                            StopTime: '23:59:59',
                            DateFlag: '',
                            Device: []
                        });
                    }
                }
                return true;
            });
        };

        // 楼层数据
        const floorOptions = ref<{key: string;value: string}[]>([{
            key: 'all',
            value: WordList.AllFloors
        }]);
        for (let i = 1; i < 129; i += 1) {
            floorOptions.value.push({
                key: i.toString(),
                value: i.toString()
            });
        }

        // 最多只能选10个floor层
        function changeFloor(type: 'community'|'office') {
            if (type === 'community') {
                if (accessForm.AccessFloor.length > 10) {
                    accessForm.AccessFloor.pop();
                }
            }
            if (type === 'office') {
                if (accessForm.Floor.length > 10) {
                    accessForm.Floor.pop();
                }
            }
        }

        return {
            breadHeaders,
            step,
            basicData,
            accessForm,
            accountType,
            roomChange,
            ruleAccess,
            selfAccessForm,
            checkForm,
            formSubmit,
            formCommit,
            buildName,
            aptName,
            isShowSelfAccess,
            chooseFile,
            removeFile,
            selfAccessCommit,
            haveSelfDevices,
            multipleSelectValue,
            submitAccess,
            getSelfAccess,
            sysAccess,
            getSysAccess,
            projectType,
            officeFormSubmit,
            employeeID,
            officeRole,
            routeGoToResident,
            isShowPayNow,
            closePayTips,
            isShowTips,
            isShowPay,
            mainID,
            payUserID,
            noticeType,
            noticeMainCode,
            accessFormRef,
            community,
            floorOptions,
            changeFloor,
            curFloor
        };
    }
});
