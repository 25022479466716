import {
    reactive, ref, Ref,
    computed
} from 'vue';
import { FormData } from '@/components/view/pm/set-resident';
import { access } from '@/methods/rule';
import { community } from '@/data';
import {
    ListHeaderItem, RequestData, SearchKey,
    getSearch
} from '@/components/common/list';

const initForm = () => {
    const basicData: Ref<FormData> = ref({
        UnitID: '',
        RoomID: '',
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneCode: '',
        MobileNumber: '',
        Phone: '',
        Phone2: '',
        Phone3: '',
        DepartmentID: '',
        Role: '',
        IsMulti: 0
    });

    const accessForm: {
        PIN: string;
        Card: string;
        AccessGroup: Array<string>;
        Floor: string[];
        AccessFloor: string[];
    } = reactive({
        PIN: '',
        Card: '',
        AccessGroup: [],
        Floor: [],
        AccessFloor: []
    });

    const fileData: Ref<File | ''> = ref('');

    const chooseFile = (file: File) => {
        fileData.value = file;
    };
    const removeFile = () => {
        fileData.value = '';
    };

    return {
        accessForm,
        fileData,
        chooseFile,
        removeFile,
        basicData
    };
};

const initRule = () => {
    const ruleAccess = computed(() => ({
        PIN: [{
            validator: access.checkPin(community.IsEncryptPin.value === '0', 'resident'),
            trigger: 'blur'
        }],
        Card: [{
            validator: access.checkCard,
            trigger: 'blur'
        }]
    }));

    return {
        ruleAccess
    };
};

const initSelfAccess = () => {
    const form: {
        Name: string;
        SchedulerType: string;
        StartDay: string;
        StopDay: string;
        StartTime: string;
        StopTime: string;
        DateFlag: string;
        Device: Array<{MAC: string; Relay: string;SecurityRelay?: string}>;
    } = reactive({
        Name: '',
        SchedulerType: '1',
        StartDay: '',
        StopDay: '',
        StartTime: '00:00:00',
        StopTime: '23:59:59',
        DateFlag: '',
        Device: []
    });

    return form;
};

type ProjectDataType = {
    [key in ProjectType]: {
        header: Array<ListHeaderItem>;
        requestData: RequestData;
        searchKeyList: SearchKey;
        deleteUrl: string;
    };
}
// 获取办公、社区区分的相关数据
const getProjectData = () => {
    const communityHeader: Array<ListHeaderItem> = [{
        name: 'Account',
        label: WordList.TabelPersonUserInHtmlUID
    }, {
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        headerType: 'customize',
        type: 'customize'
    }, {
        name: 'Contact',
        label: WordList.ProperAllTextContactInformation,
        type: 'customize'
    }, {
        name: 'UnitName',
        label: WordList.DeviceDetailDetailUnitName
    }, {
        name: 'RoomName',
        label: WordList.RDeviceSearchLabelRoomName,
        headerType: 'customize'
    }, {
        name: 'AptName',
        label: WordList.RDeviceSearchLabelRoomNumber
    }, {
        name: 'Initialization',
        label: WordList.ProperAllTextAppStatus,
        type: 'customize'
    }];
    const officeHeader: Array<ListHeaderItem> = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName,
        headerType: 'customize',
        type: 'customize'
    }, {
        name: 'EmployeeID',
        label: WordList.ProperAllTextID
    }, {
        name: 'Contact',
        label: WordList.ProperAllTextContactInformation,
        type: 'customize'
    }, {
        name: 'DepartmentName',
        label: WordList.ProperAllTextDepartment
    }, {
        name: 'Initialization',
        label: WordList.ProperAllTextAppStatus,
        type: 'customize'
    }];
    const communityRequestData: RequestData = reactive({
        url: 'v3/web/community/user/getListByNewPm',
        param: getSearch({
            Build: 'all',
            Room: 'all',
            Role: 'all',
            Active: 'all',
            Status: 'all',
            searchKey: 'Name',
            searchValue: ''
        })
    });
    const officeRequestData: RequestData = reactive({
        url: 'v3/web/office/user/getOfficeUserList',
        param: getSearch({
            DepartmentID: 'all',
            Role: 'all',
            Active: 'all',
            Status: 'all',
            searchKey: 'Name',
            searchValue: ''
        })
    });
    const communitySearchKeyList = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber
    }];
    const officeSearchKeyList = [{
        name: 'Name',
        label: WordList.TabelPersonUserInHtmlName
    }, {
        name: 'EmployeeID',
        label: WordList.ProperAllTextID
    }, {
        name: 'Email',
        label: WordList.TabelPropertyManageEmail
    }, {
        name: 'MobileNumber',
        label: WordList.ProperAllTextMobileNumber
    }];
    const communityDeleteUrl = 'v3/web/community/user/deleteForPm';
    const officeDeleteUrl = 'v3/web/office/user/deleteOfficeUser';
    const projectData: ProjectDataType = {
        community: {
            header: communityHeader,
            requestData: communityRequestData,
            searchKeyList: communitySearchKeyList,
            deleteUrl: communityDeleteUrl
        },
        office: {
            header: officeHeader,
            requestData: officeRequestData,
            searchKeyList: officeSearchKeyList,
            deleteUrl: officeDeleteUrl
        }
    };
    return projectData;
};

export default null;
export {
    initForm,
    initRule,
    initSelfAccess,
    getProjectData
};
